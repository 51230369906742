import type { Location } from "@warrenio/api-spec/spec.oats.gen";
import { sortBy } from "remeda";
import type { ResourceType } from "../api/resourceTypes.tsx";
import type { LocationSlug } from "./query.ts";

export interface LocationWithEnabled extends Location {
    /** Whether the location is enabled for the current resource type. */
    isEnabled: boolean;
}

export function sortLocations(locations: LocationWithEnabled[]) {
    return sortBy(locations, (l) => l.order_nr);
}

export function getPreferredLocation(locations: LocationWithEnabled[], selectedLocation?: LocationSlug) {
    const enabled = getEnabledLocations(locations);
    return enabled.find((s) => s.slug === selectedLocation) ?? enabled.find((s) => s.is_preferred) ?? enabled[0];
}

export function getEnabledLocations(locations: LocationWithEnabled[]) {
    return locations.filter((l) => l.isEnabled);
}

//#region Resource type locations

export function canUseLocationForType(location: Location, resourceType: ResourceType | undefined) {
    if (location.create_resource_disabled) {
        return false;
    }

    if (resourceType === "load_balancer" || resourceType === "vpc") {
        if (location.mikrotik) {
            return false;
        }
    }

    return true;
}

function getLocationWithEnabledForType(
    locations: Location[],
    resourceType: ResourceType | undefined,
): LocationWithEnabled[] {
    return sortLocations(
        locations.map((l) => ({
            ...l,
            isEnabled: canUseLocationForType(l, resourceType),
        })),
    );
}

export function getDefaultLocationForType(allLocations: Location[], resourceType: ResourceType | undefined) {
    const typeLocations = getLocationWithEnabledForType(allLocations, resourceType);
    const location = getPreferredLocation(typeLocations);
    if (location === undefined) {
        throw new Error(`No location found for resource type ${resourceType}`);
    }
    return location;
}

/** Bundled information useful for selecting a location for a resource. */
export interface LocationsForType {
    resourceType: ResourceType | undefined;

    /** Contains **all** locations, but only applicable locations have `isEnabled` set. */
    locations: LocationWithEnabled[];
    defaultLocationItem: LocationWithEnabled;
    defaultLocation: LocationSlug;

    /** Is there the possibility to select a location? (ie. more than one applicable location) */
    canSelect: boolean;
    showLocations: boolean;
}

export function getLocationsForType(
    allLocations: Location[],
    resourceType: ResourceType | undefined,
): LocationsForType {
    const locations = getLocationWithEnabledForType(allLocations, resourceType).filter(
        // XXX: Always show default location since `VmCreate` code executes `.find()` on it.
        // Fix this: WRN-177
        (l) => l.is_published == null || l.is_published || l.is_preferred,
    );
    const defaultLocation = getDefaultLocationForType(allLocations, resourceType);

    return {
        resourceType,

        locations,
        defaultLocationItem: defaultLocation,
        defaultLocation: defaultLocation.slug,

        canSelect: locations.filter((l) => l.isEnabled).length > 1,
        showLocations: locations.length > 0,
    };
}

//#endregion
